import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import './page.css'
import { ICounter } from './App'

function Home(props: { counters: ICounter[] }) {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                margin: 0,
                padding: 0,
                top: 0,
                left: 0,
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#D2E9FC',
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    padding: 10,
                    justifyContent: 'space-between',
                }}
            >
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            color: '#1F539A',
                            fontSize: 30,
                            fontWeight: 700,
                        }}
                    >
                        STT/No
                    </div>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            color: '#1F539A',
                            fontSize: 30,
                            fontWeight: 700,
                        }}
                    >
                        Quầy/Teller
                    </div>
                </div>
                {props.counters.map((v, i) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 20,
                                flex: 1,
                                maxHeight: 80,
                            }}
                        >
                            <div
                                className={v.calling ? 'blinking-text' : ''}
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: '#F8703B',
                                    fontSize: 40,
                                    fontWeight: 700,
                                }}
                            >
                                {v.ticket_number}
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 20,
                                    justifyContent: 'center',
                                }}
                            >
                                <div style={{ padding: 20 }}>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        color="#1F539A"
                                        fontSize={30}
                                    />
                                </div>
                                <div
                                    style={{
                                        padding: 20,
                                        backgroundColor: '#1F539A',
                                        borderRadius: 10,
                                        color: 'white',
                                        fontSize: 30,
                                        fontWeight: 700,
                                    }}
                                >
                                    {v.num < 10 ? `0${v.num}` : v.num}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

function splitArray(counters: ICounter[]) {
    const mid = Math.ceil(counters.length / 2)
    const firstHalf = counters.slice(0, mid)
    const secondHalf = counters.slice(mid)
    return [firstHalf, secondHalf]
}

export function HomeKiosk(props: { counters: ICounter[] }) {
    const [firstHalf, secondHalf] = splitArray(props.counters)
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#D2E9FC',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            color: '#1F539A',
                            fontSize: 30,
                            fontWeight: 700,
                        }}
                    >
                        STT/No
                    </div>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            color: '#1F539A',
                            fontSize: 30,
                            fontWeight: 700,
                        }}
                    >
                        Quầy/Teller
                    </div>
                </div>
                {firstHalf.map((v, i) => {
                    return (
                        <div style={{ display: 'flex', marginTop: 20 }}>
                            <div
                                className={v.calling ? 'blinking-text' : ''}
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: '#F8703B',
                                    fontSize: 40,
                                    fontWeight: 700,
                                }}
                            >
                                {v.ticket_number}
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 20,
                                    justifyContent: 'center',
                                }}
                            >
                                <div style={{ padding: 20 }}>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        color="#1F539A"
                                        fontSize={30}
                                    />
                                </div>
                                <div
                                    style={{
                                        padding: 20,
                                        backgroundColor: '#1F539A',
                                        borderRadius: 10,
                                        color: 'white',
                                        fontSize: 30,
                                        fontWeight: 700,
                                    }}
                                >
                                    {v.num < 10 ? `0${v.num}` : v.num}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#D2E9FC',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10,
                }}
            >
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            color: '#1F539A',
                            fontSize: 30,
                            fontWeight: 700,
                        }}
                    >
                        STT/No
                    </div>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            color: '#1F539A',
                            fontSize: 30,
                            fontWeight: 700,
                        }}
                    >
                        Quầy/Teller
                    </div>
                </div>
                {secondHalf.map((v, i) => {
                    return (
                        <div style={{ display: 'flex', marginTop: 20 }}>
                            <div
                                className={v.calling ? 'blinking-text' : ''}
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: '#F8703B',
                                    fontSize: 40,
                                    fontWeight: 700,
                                }}
                            >
                                {v.ticket_number}
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 20,
                                    justifyContent: 'center',
                                }}
                            >
                                <div style={{ padding: 20 }}>
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        color="#1F539A"
                                        fontSize={30}
                                    />
                                </div>
                                <div
                                    style={{
                                        padding: 20,
                                        backgroundColor: '#1F539A',
                                        borderRadius: 10,
                                        color: 'white',
                                        fontSize: 30,
                                        fontWeight: 700,
                                    }}
                                >
                                    {v.num < 10 ? `0${v.num}` : v.num}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Home
