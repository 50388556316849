import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Home, { HomeKiosk } from './page'
import Teller from './teller'

import './App.css'

export interface ICounter {
    num: number
    calling: boolean
    ticket_number: number
}

const counterNumber = 8

const soundPath = 'sounds/hanoi'

function App() {
    const [counters, setCounters] = useState<ICounter[]>([])
    const isInit = useRef(false)

    const handleCallTicket = (counter: number, ticket_number?: number) => {
        setCounters((prev) => {
            let nextNumber = 0
            const newCounters = prev.map((v) => {
                if (v.num === counter) {
                    nextNumber = ticket_number || v.ticket_number + 1
                    return {
                        ...v,
                        calling: true,
                        ticket_number: nextNumber,
                    }
                }
                return v
            })
            console.log(counter, nextNumber)
            playVoice(nextNumber, counter)

            return newCounters
        })

        setTimeout(() => {
            setCounters((prev) =>
                prev.map((v) => {
                    if (v.num === counter) {
                        return {
                            ...v,
                            calling: false,
                        }
                    }
                    return v
                })
            )
        }, 5000)
    }

    const playVoice = (ticket_number: number, counter: number) => {
        const digits = ticket_number.toString().split('')

        const sequence = [
            `${soundPath}/_xinmoisove.mp3`,
            ...digits.map((digit) => `${soundPath}/${digit}.mp3`),
            `${soundPath}/_denquayso.mp3`,
            `${soundPath}/${counter}.mp3`,
        ]

        const playAudio = (index: number) => {
            if (index >= sequence.length) return

            console.log('play:', sequence[index])

            const audio = new Audio(sequence[index])
            audio.play().catch((err) => {
                showInteractionPopup()
            })

            audio.onended = () => {
                playAudio(index + 1)
            }
        }

        playAudio(0)
    }

    const randomCallTicket = () => {
        setTimeout(() => {
            const counter = Math.floor(Math.random() * counterNumber) + 1
            handleCallTicket(counter)
            randomCallTicket()
        }, 10000)
    }

    const showInteractionPopup = () => {
        const popup = document.createElement('div')
        popup.style.position = 'fixed'
        popup.style.top = '50%'
        popup.style.left = '50%'
        popup.style.transform = 'translate(-50%, -50%)'
        popup.style.padding = '20px'
        popup.style.backgroundColor = 'white'
        popup.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.1)'
        popup.style.textAlign = 'center'
        popup.style.zIndex = '1000'

        const message = document.createElement('p')
        message.textContent = 'Nhấn vào đây để cho phép phát âm thanh'
        popup.appendChild(message)

        const button = document.createElement('button')
        button.textContent = 'Phát âm thanh'
        button.onclick = () => {
            document.body.removeChild(popup)
        }
        popup.appendChild(button)

        document.body.appendChild(popup)
    }

    useEffect(() => {
        setCounters(
            Array.from({ length: counterNumber }).map((v, i) => {
                return {
                    num: i + 1,
                    ticket_number: Number(`${i + 1}000`),
                } as ICounter
            })
        )

        if (!isInit.current) {
            isInit.current = true
            randomCallTicket()
        }
    }, [])

    return (
        <Router>
            <Route path="/" exact>
                <Home counters={counters} />
            </Route>
            <Route path="/kiosk">
                <HomeKiosk counters={counters} />
            </Route>
        </Router>
    )
}

export default App
